.sidr {
  display: block;
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 999999;
  width: 260px;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 15px;
  background: $white-c;
  color: $black-c;
  border-right: 1px solid $gray-c;
  .submenu-trigger{
    svg{
      display: none;
    }
  }
}
.sidr .sidr-inner {
  padding: 10px 0;
}
.sidr .sidr-inner > p {
  margin-left: 15px;
  margin-right: 15px;
}
.sidr.right {
  left: auto;
  right: -260px;
}
.sidr.left {
  left: -260px;
  right: auto;
}

.sidr ul {
  display: block;
  margin:  0;
  padding: 0;
  border: none;
}
.sidr ul li {
  display: block;
  margin: 0;
  line-height: 25px;
  border-bottom: 1px solid $gray-c;
}
.sidr ul li a {
  padding:10px 25px 10px 15px;
}

.sidr ul li:hover > a,
.sidr ul li:hover > span, .sidr ul li.active > a,
.sidr ul li.active > span, .sidr ul li.sidr-class-active > a,
.sidr ul li.sidr-class-active > span {
  color: $color1-c;
}
.sidr ul li a,
.sidr ul li span {
  display: block;
  text-decoration: none;
  color: $black-c;
}
.sidr ul ul li a,
.sidr ul ul li span {
  padding-left: 25px;
}
.sidr ul ul ul li a,
.sidr ul ul ul li span {
  padding-left: 35px;
}
.sidr ul li ul {
  margin: 0;
  border-top: 1px solid $color1-c;
  border-bottom: 0;
  background:#f5f5f5;
}
.sidr ul ul > li:last-of-type {
  border-bottom: 0;
}
.sidr ul ul li:hover > a,
.sidr ul ul li:hover > span, .sidr ul li ul li.active > a,
.sidr ul ul li.active > span, .sidr ul li ul li.sidr-class-active > a,
.sidr ul ul li.sidr-class-active > span {
  color: $color1-c;
}

.sidr ul i {
  display: none;
}

.sidr ul li {
  position: relative;
  .submenu-trigger {
    cursor: pointer;
    position: absolute;
    right: 0; top: 9px;
    width: 25px;
    height: 25px;
    background-color: $color1-c;
    display: inline-block;
    padding: 0;
    z-index: 1;
    &:before {
      content: '';
      position: absolute;
      width: 10px; height: 2px;
      right: 7px;
      @include center(y);
      background-color: $white-c;
    }
    &:after {
      content: '';
      position: absolute;
      width: 10px; height: 2px;
      right: 7px;
      @include center(y);
      @include transform(rotate(90deg));
      background-color: $white-c;
      margin-top: -1px;
      @include transition-all(0.4s);
    }
    &.open-submenu {
      &:after {
        @include transform(rotate(0deg));
        @include transition-all(0.4s);
      }
    }
  }
}

.sidr-inner ul li > div,
.sidr-inner ul li > ul {
  display: none;
}
