// ***********************************
// PAGINATION
// ***********************************

/* Pagination */
.pagination {
  @extend .clearfix;
}

ul.pagination-list{
  text-align: center;
  padding: 30px 0;
  //  @media(max-width: 767px){
  //    text-align: center;
  //  }
}
ul.pagination-list li{
  display: inline-block;
  width: 26px; height: 26px;
  margin-right: 5px;
  vertical-align: top;
}
ul.pagination-list li a{
  display: block;
  line-height: 24px; width: 100%;
  border: 1px solid $gray-c;
  text-align: center;
  color: inherit;
  position: relative;
  cursor: pointer;
}
ul.pagination-list li a.disabled{
  cursor: default;
  pointer-events: none;
}
ul.pagination-list li a:hover{
  color: inherit;
  text-decoration: none;
  background-color: $color1-c;
  color: $white-c;
}
ul.pagination-list li a.current{
  background-color: $color1-c;
  cursor: default;
  pointer-events: none;
  color: $white-c;
}
ul.pagination-list li:last-of-type{
  margin: 0;
}
@media(max-width: 580px){
  ul.pagination-list{
    width: 100%;
    text-align: center;
  }
}