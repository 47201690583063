
.newsletter-section {
  padding: 60px 0;
  position: relative;
  background: $color1-c;

  .title-n{
    font-size: $font-size-h1;
    font-weight: 700;
    color: $white-c;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .newsletter-p{
    color: $white-c;
    font-size: 1rem;
  }
  #form_newsletter {
    padding: 20px 0 0 0;
    //width: 760px;
    margin: 0 auto;

    color: #fff;
    position: relative;

    .text-input{
      width: 50%;
    }

    .gcaptcha-submit{
      position: absolute;
      right: 0;
      width: 50%;
      top: 0;
      padding: 20px;
      box-sizing: border-box;
      height: 100%;

      .g-recaptcha{
        width: 50%;
        transform:scale(0.55);
        -webkit-transform:scale(0.55);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
        display: inline-block;
        float: left;
      }
      .submit-box{
        width: 50%; 
        float: right;
        box-sizing: border-box;
        padding-left: 10px;
      }
    }

    .submit_box{/*width: 92px;*/  text-align: center }

    input[type="text"]{
      padding: 10px 15px;
      
    }

    input[type="submit"]{
      background: none;
      border: 1px solid #fff;
      color: $black-c;
      cursor: pointer; padding: 10px 20px;
      background-color: $white-c;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  #newsleter_form_error{
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    font-weight: bold;
    left: 0;
  }
  @include media-breakpoint-down(md) {
    padding: 20px 0;
    #form_newsletter {
      padding: 20px 0 0 0;
      .gcaptcha-submit{
        padding: 20px 10px;
        .submit-box{
          width: auto;
        }
      }
    }
  }
}


@include media-breakpoint-down(sm) {
  .newsletter-section {
    width: 100%;

    #form_newsletter {
      width:100%;

      .text-input{width:100%;}
      .gcaptcha-submit{
        width:100%;
        position: static;
        padding: 20px 0 0 0;
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .newsletter-section #form_newsletter input[type="submit"] {
    z-index: 2;
    position: relative;
  }
}