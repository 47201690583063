.gallery-adlbums{
  .text-editor{
    padding-top: 15px;
    padding-bottom: 15px;
    float: none;

    @include clearfix;
  }

  .gallery-album-list{
    padding-top: 5px;

    @include clearfix;
  }
}
.gallery-album-item{
  padding: 10px;

  > div{
    border:1px solid #ddd;

    > h2{
      padding: 10px;
      font-weight: bold; font-size: $font-size-base;
      position: relative;
      height: 60px;

      > a{
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        padding-left: 20px;

        /* styles for '...' */

          /* Over flow hidden hides the text if it is more than N lines  */
          overflow: hidden;
          /* To set '...' in absolute position */
          position: relative;
          /* Use this value to count block height */
          line-height: 1.2em;
          /* Max-height = line-height (1.2) * lines max number (2) */
          max-height: 2.6em;
          /* If the last visible word doesn't adjoin right side then this will fix the problem */
          // text-align: justify !important;
          /* place for '...' */
          padding-right: 20px;


        /* create the ... */
        &:before {
          /* points in the end */
          content: '...';
          /* absolute position */
          position: absolute;
          /* set position to right bottom corner of block */
          right: 10px;
          bottom: 3px;
        }

        /* hide ... if we have text, which is less than or equal to max lines */
        &:after {
          /* points in the end */
          content: '';
          /* absolute position */
          position: absolute;
          /* set position to right bottom corner of text */
          right: 10px;
          /* set width and height */
          width: 1em;
          height: 1em;
          margin-top: 0.2em;
          /* bg color = bg color under block */
          background: white;
        }

        @include center(xy);
      }
    }

    > .short-text{
      position: relative;
      height: 60px;
      padding: 0 0 10px 0;

      p{
        font-size: $font-size-base;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        padding-left: 20px;

        overflow: hidden;
        position: relative;
        line-height: 1.3em;
        max-height: 2.6em;
        padding-right: 20px;

        @include center(xy);

        &:before {
          content: '...';
          position: absolute;
          right: 10px;
          bottom: 0;
        }

        &:after {
          content: '';
          position: absolute;
          right: 10px;
          width: 1em;
          height: 1em;
          margin-top: 0.2em;
          background: white;
        }

      }
    }
  }

  &:hover{
    img{opacity: 0.8; @include transition-all(.4s);}

    h2 a{color: $color2-c; @include transition-all(.4s);}
  }
}

/* Gallery Album Items image */
.gallery-album-image.gallery-on-page{

  .lightgallery{
    a{

      span{
        border: 1px solid #ddd;
        height: 118px;
        line-height: 116px;

        img{
          margin-top: 0px;
          max-height: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .gallery-album-image.gallery-on-page{
    .lightgallery{
      a{
        span{
          height: 98px;
          line-height: 96px;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .gallery-album-image.gallery-on-page{
    .lightgallery{
      a{
        span{
          height: 72px;
          line-height: 70px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .gallery-album-image.gallery-on-page{
    .lightgallery{
      a{
        span{
          height: 82px;
          line-height: 80px;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .gallery-album-image.gallery-on-page{
    .lightgallery{
      a{
        span{
          height: 110px;
          line-height: 108px;
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .gallery-album-image.gallery-on-page{
    .lightgallery{
      a{
        span{
          height: 76px;
          line-height: 74px;
        }
      }
    }
  }
}