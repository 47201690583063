.navigation {
  padding: 10px 0;

  nav > ul {
    margin: 0;
    display: flex;
  align-items: center;

    li {
      list-style: none;
      position: relative;
      &:before {
        content: '';
        bottom: -1px;
        left: 50%;
        height: 2px;
        background-color: $blue;
        position: absolute;
        transform: translate(-50%,-6px);
        width: 0;
        transition: all .2s ease-in-out;
      }
    }
    .active > a, .onPath > a{
      color: $color1-c;
    }

    .active, .onPath{
      &:before{
        width: 100%;
      }
      .submenu-trigger{
        svg {
          path{
            fill: $blue;
            @include transition-all(0.4s);
          }
        }
      }
    }

    a {
      display: block;
    }

    > li {
      display: flex;
      align-items: center;
      margin-right: 16px;
      font-size: 1.125rem;
      position: relative;
      text-transform: uppercase;

      .submenu-trigger {
        display: flex;
      }

      &:hover{
        &:before{
          width: 100%;
        }
        .submenu-trigger{
          svg {
            path{
              fill: $blue;
              @include transition-all(0.4s);
            }
          }
        }
      }

      @include media-breakpoint-only(lg) {
        margin-right: 5px;
      }


      > a {
        padding: 10px 7px;
        line-height: 20px;
        color: $text-c;
        font-weight: 600;
        display: inline-block;

        > span {
          color: $color2-c;
        }
      }

      // SUBMENU
      > ul {
        position: absolute;
        left: 0; top: 110%;
        opacity: 0; visibility: hidden;
        z-index: -1;
        /*white-space: nowrap;*/
        min-width: 160px;
        @include transition(opacity 0.4s, top 0.4s);

        > li{
          padding-right: 2px;
          font-weight: 600;
          &.active{
            &:before{
              display: none;
            }
          }

          .submenu-trigger{
            position: absolute;
            right: 0;
            top: 13px;
            transform: rotate(-90deg);
          }
          &:hover, &.active{
            >.submenu-trigger:after{
              color: $color1-c;
              @include transition-all(0.4s);
            }
          }
        }
      }
      &:hover {
        > a {
          color: $color1-c;
        }
        > ul {
          top: 100%;
          opacity: 1; visibility: visible;
          z-index: 1;
          background-color: #FFF;
          padding: 0;
          border-top: 1px solid #dddddd;
          border-left: 1px solid #dddddd;
          border-right: 1px solid #dddddd;

          &:before {
            content: "";
            font-size: 22px; line-height: 10px;
            color: transparent;
            display: inline-block;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent transparent transparent;
            border-style: solid;
            position: absolute;
            bottom: 100%; left: 20px;
          }

          li{border-bottom: 1px solid #dddddd;}

          a {
            //color: $white-c;
            padding: 10px 15px;

            &:hover {
              color: $color1-c;
            }
          }
        }
      }
    }

    > li li {
      position: relative;
      white-space: nowrap;
      &:before {
        display: none;
      }
      > ul {
        background-color: #FFF;
        padding: 0;
        position: absolute;
        left: 100%;
        top: 0;
        margin-left: 10px;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        min-width: 160px;
        border-top: 1px solid #dddddd;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        @include transition(opacity 0.4s, margin-left 0.4s); 

        &:before {
          content: "";
          position: absolute;
          top: 14px; left: -7px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 7px 8px 0;
          border-color: transparent #0274be transparent transparent;
        }

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          left: -16px; top: 0;
          width: 16px; height: 100%;
        }

      }

      &:hover {
        > ul {
          margin-left: 10px;
          opacity: 1; visibility: visible;
          z-index: 1;
        }
      }
    }


    > li > li  {
      ul{
        opacity: 0; visibility: hidden;
      }
      &:hover ul {opacity: 1; visibility: visible;}
    }

  }
}


#responsive-menu-button {
  display: none;
  width: 30px;
  height: 30px;
  position: relative;
  @include transform(rotate(0deg));
  @include transition-all(0.25s);
  cursor: pointer;


  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $color1-c;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    @include transform(rotate(0deg));
    @include transition-all(0.25s)
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2),span:nth-child(3) {
    top: 13px;
  }

  span:nth-child(4) {
    top: 26px;
  }
}

.sidr-animating,
.sidr-open {
  #responsive-menu-button {

    span:nth-child(1) {
      left: 50%; top: 13px;
      width: 0%;
    }

    span:nth-child(2) {
      top: 13px;
      @include transform(rotate(45deg));
    }

    span:nth-child(3) {
      top: 13px;
      @include transform(rotate(-45deg));
    }

    span:nth-child(4) {
      left: 50%; top: 13px;
      width: 0%;
    }
  }
}


#navigation {
  display: inline-block;
}

.fixed-header {
  padding-top: 67px;

  #header {
    section.main {
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      -webkit-animation-duration: 600ms;
      animation-duration: 600ms;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
      z-index: 10;
      background-color: $white-c;
      padding: 0;
      border-top: 1px solid $gray-c;
      border-bottom: 1px solid $gray-c;
      .logo{
        width: 50px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 52px;
    #header{
      section.main{
        padding: 5px 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .fixed-header {
    .navigation {
      #responsive-menu-button {
        display: none;
      }
    }
  }
}



@include media-breakpoint-down(md){
  #responsive-menu-button {
    display: block; float: right;
  }
  #navigation {
    display: none;
  }
}


/* Right column menu navigation */
.sidebar-menu{
  margin-bottom: 60px;
  ul{
    border-top: 1px solid rgba(32, 32, 32, 0.30);
    li{
      position: relative;

      a{
        padding: 9px 5px 9px 20px;
        display: block;
        font-size: 1.125rem;
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 1px solid rgba(32, 32, 32, 0.30);
      }

      a:hover, &.active > a{
        color: $white-c;
        background-color: $blue;
      }

      ul{
        padding-left: 10px;
        //display: none;

        li{

          a{
            border-bottom: 1px solid #DDDDDD;
            font-weight: normal;
          }
        }
      }

      //&.onPath ul{display: block;}

      &:last-child a{
        //border-bottom: none;
      }

    }
  }
  @include media-breakpoint-down(md){
    margin-bottom: 20px;
  }
}