footer {
  color: $black-c;
  background-color: $gray-c;

  .footer-c{
    padding: 60px 0;
  }

  img {
    margin-bottom: 15px;
  }

  .foo-block {

    &.f_about{
      text-align: center;
    }
    &.contact{
      border-right: none;
      .contact-block{
        max-width: 330px;
        margin: 0 auto;
      }
    }

    .f_about_text{
      margin-bottom: 30px;
    }

    .title-f {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 55px;
    }

    ul {
      li {
        display: block;
        margin-bottom: 10px;
      }
      a {
        display: inline-block;
        position: relative;
        color: $black-c;

        &:hover {
          color: $color2-c
        }
      }
    }

    table {
      margin-top: -10px;
    }
    tbody > tr{
      td {
        width: 300px;
        padding-left: 28px;
        position: relative;
        font-size: 0.875rem;
        padding-top: 7px;
        padding-bottom: 7px;
        &:before {
          color: $blue;
          position: absolute;
          left: 0px; top: 50%;
          @include translate(0, -50%);
          font-size: 1.5rem;
          font-family: FontAwesome;
        }
      }

      &:nth-of-type(1) td:before {
        content: "\f041";
      }
      &:nth-of-type(2) td:before {
        content: "\f095";
      }
      &:nth-of-type(3) td:before {
        content: "\f0e0";
      }
      &:nth-of-type(4) td:before {
        content: "\f017";
      }
    }

    .social{
      border-top: 1px solid #dddddd;
      margin: 10px 0 0;
      padding-top: 10px;

      a{
        border-radius: 50%;
        width: 30px;
        height:30px;
        margin-right: 10px;
        background: $color1-c;
        color: #FFF;
        line-height: 30px; text-align: center;
        display: inline-block;

        &:hover{
          background: $color2-c;
          @include transition-all(.4s);
        }
      }
    }
    @include media-breakpoint-down(md){
      margin-bottom: 20px;
    }
  }

  .copyright {
    border-top: 1px solid #DDD;
    background-color: $black-c;
    display: block;
    color: $white-c;
    font-size: 0.75rem;
    font-weight: 300;
    padding: 20px 0 20px;

    .client{
      opacity: 0.75; 
    }
    .power{text-align: right;}

    .fa {
      color: $color1-c;
    }
    a {
      color: $white-c;
      opacity: 1; 
      font-weight: 400;

      &:hover{
        text-decoration: underline;
        @include transition-all(.4s);
      }
    }

    @include media-breakpoint-down(md) {
      .client, .power {
        text-align: center;
      }
    }
  }
  @include media-breakpoint-down(md){
    .footer-c{
      padding: 20px 0 0 0;
    }
    .foo-block {
      .title-f{
        margin-bottom: 20px;
      }
      .f_about_text{
        margin-bottom: 5px;
      }
    }
  }
}