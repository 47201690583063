// ***********************************
// MIXINS
// ***********************************

// center vertically and/or horizontally an absolute positioned element

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin transition-all($time:1s){
  -webkit-transition: all $time ease-in-out;
  -moz-transition: all $time ease-in-out;
  -ms-transition: all $time ease-in-out;
  -o-transition: all $time ease-in-out;
  transition: all $time ease-in-out;
}

@mixin transition($var...) {
  -webkit-transition: $var;
  -moz-transition: $var;
  -ms-transition: $var;
  -o-transition: $var;
  transition: $var;
}

@mixin fade($time:0.5s){
  -webkit-transition: background-color $time ease-in-out;
  -moz-transition: background-color $time ease-in-out;
  -o-transition: background-color $time ease-in-out;
  -ms-transition: background-color $time ease-in-out;
  transition: background-color $time ease-in-out;
}
// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
// translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}

// translate3d
@mixin translate3d ($x, $y, $z) {
  @include transform(translate($x, $y, $z));
}

//placeholder
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}