/* Find Us On Google Map */

.find-us-on-map{
  background: #EEEEEE;

  .map-trigger-holder{
    text-align: center;
    font-size: 1rem; font-weight: bold;
    text-transform: uppercase;

    &:hover{
      cursor: pointer;
    }

    .map-trigger{
      padding: 30px 0;
      display: block;
    }
  }

  .map{
    height: 400px;
    position: relative;
    display: none;
  }
}