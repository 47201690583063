/* Gallery on page - lightgallery */
.gallery-on-page{
  .text-editor{
    padding: 20px 0;

    h3{
      margin-bottom: 15px;
    }
  }

  .lightgallery{
    a{
      padding:7px;

      span{
        border: 1px solid #ddd;
        display: inline-block;
        height: 129px;
        vertical-align: middle;
        line-height: 128px;
        text-align: center;
        min-width: 100%;
        font-size: 0;
        box-sizing: border-box;

        img{
          height: auto;
          max-width: 100%;
          max-height: 127px;
          margin-top: -1px;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .gallery-on-page .lightgallery a span {
    height: 106px;
    line-height: 104px;

    img{
      margin-top: 0px;
      max-height: 100%;
    }
  }
}

@include media-breakpoint-down(md) {
  .gallery-on-page .lightgallery a span {
    height: 76px;
    line-height: 74px;
  }
}

@include media-breakpoint-down(sm) {
  .gallery-on-page .lightgallery a span {
    height: 78px;
    line-height: 76px;
  }
}

@include media-breakpoint-down(xs) {
  .gallery-on-page .lightgallery a span {
    height: 94px;
    line-height: 92px;
  }
}

@media (max-width: 380px)  {
  .gallery-on-page .lightgallery a span {
    height: 78px;
    line-height: 66px;
  }
}

@media (max-width: 360px)  {
  .gallery-on-page .lightgallery a span {
    height: 68px;
    line-height: 66px;
  }
}

@media (max-width: 320px)  {
  .gallery-on-page .lightgallery a span {
    height: 58px;
    line-height: 56px;
  }
}