// ***********************************
// VARIABLES
// ***********************************


@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/montserrat/Montserrat-MediumItalic.eot');
  src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
      url('../fonts/montserrat/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-MediumItalic.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-MediumItalic.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Italic.eot');
  src: local('Montserrat Italic'), local('Montserrat-Italic'),
      url('../fonts/montserrat/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-Italic.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-Italic.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
      url('../fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
      url('../fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Light.eot');
  src: local('Montserrat Light'), local('Montserrat-Light'),
      url('../fonts/montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-Light.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-Light.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
      url('../fonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-Medium.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ExtraBoldItalic.eot');
  src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
      url('../fonts/montserrat/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-ExtraBoldItalic.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-SemiBoldItalic.eot');
  src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
      url('../fonts/montserrat/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-SemiBoldItalic.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-ExtraBold.eot');
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
      url('../fonts/montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-ExtraBold.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-BoldItalic.eot');
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
      url('../fonts/montserrat/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-BoldItalic.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-BoldItalic.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-LightItalic.eot');
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
      url('../fonts/montserrat/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-LightItalic.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-LightItalic.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat/Montserrat-SemiBold.eot');
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
      url('../fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
      url('../fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
      url('../fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}



// TYPOGRAPHY
$font-family1: 'Montserrat', sans-serif;

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {

  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }



// COLORS
$white-c: #fff;
$black-c: #535353;
$gray-c: #EFEFEF;
$red-c: #E82C2C;
$green-c: #127486;
$green-c1: #1BD3AF;
$blue: #0274BE;
$brown: #545454;
$brown-2: #BDA09C;


$text-c: $black-c;
$rectangle-c: $black-c;
$border-c: $gray-c;

$color1-c: $blue;
$color2-c: $green-c1;

$alert-color: $red-c;
$success-color-c: $green-c;

$block-margin-bottom: 40px;