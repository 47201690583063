/* News - Home Latest News, List News & Article */

/* Home List News*/
.latest-news{
  padding: 60px 0;
  @include media-breakpoint-down(md){
    padding: 20px 0;
  }
}

.news{
  border:1px solid #DDDDDD;

  .text_block{
    padding: 20px;
    background-color: $gray-c;

    .date_author_comments{
      display: inline-block; 
      width: 100%;
      padding-bottom: 20px;

      >div{display: inline-block;}

      span{ padding-right: 7px; }

      .date{
        color: $blue;
        font-weight: 700;
        font-size: 0.625rem;
      }
      .author{ }
      .comments{ }
    }

    h2, h3{
      font-weight: bold; font-size: $font-size-h3;
      margin-bottom: 0;
      min-height: 70px;
    }

    .short_text {
      height: 68px;
      display: table-cell;
      vertical-align: middle;
      width: 100%;
    }
  }

  &:hover{
    img{opacity: 0.8; @include transition-all(.4s);}

    h2 a, h3 a{color: $color1-c; @include transition-all(.4s);}
  }

}

/* News - Article */
.article{
  .date_comments_location_author{
    margin-bottom: 20px;
  }
  .short-text{
    font-size: $font-size-h3;
    color: $color1-c;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .text-editor{padding-top: 20px;}

  .tag-list{
    border-bottom: 1px dotted #999;
    padding:15px 0;
    margin-bottom: 20px;

    span{font-weight: bold;}

    span, .tags, li{display: inline-block;}

    li a{color: $color1-c;}

    li a:hover{color: $color2-c;}

  }
}

