.title-parallax {
  height: 300px;
  @extend .parallax-effect;
  text-align: center;

  .text {
    width: 100%;
    position: absolute;
    @include center(xy);
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    color: $white-c;
    text-transform: uppercase;
    text-shadow: 1px 1px 3px $black-c;
    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
}

.page-title-holder {
  position: relative;
  top: 20px;

  h1 {
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
}

//////////////////////////////////////////////////////////
//SIMPLE PAGE
//////////////////////////////////////////////////////////
.simple-page {

  .social-links {
    display: block;
    @extend .clearfix;
    margin-top: 20px;

    li {
      display: inline-block;
      float: left;

      &:not(:first-of-type) {
        a {
          border-left: 0;
        }
      }
    }

    a {
      display: block;
      line-height: 48px;
      width: 50px;
      border: 1px solid $border-c;
      color: #888;
      text-align: center;
      font-size: 1.25rem;

      &:hover {
        border-color: $color1-c;
        background-color: $color1-c;
        color: $white-c;
      }
    }
  }

}

//////////////////////////////////////////////////////////
//CONTACT PAGE
//////////////////////////////////////////////////////////
.contact-page {
  padding-top: 30px;

  h2 {
    margin-bottom: 0;
  }

  .form-box{
    h2{
      font-size: 1.75rem;
      text-transform: uppercase;
      font-weight: bold;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ccc;
    }

    input, textarea{
      padding: 10px;
      border:none;
      margin-bottom: 20px;
      color: #555;
      font-weight: 600;
      border: 1px solid rgba(32, 32, 32, 0.30);
    }
    textarea{min-height: 150px;}

    input::placeholder , textarea::placeholder {
      color: #999;
    }
    button{
      height: 40px;
      width: auto;
      background-color: $blue;
      border-radius: 0;
      border: 1px solid $color1-c;
      font-size: 14px;
      font-weight: bold;
      color: $white-c;
      float: right;
      cursor: pointer;
      position: relative; padding:0 20px;
      i{
        color: $white-c; margin-right: 10px;
      }
      &:hover{background-color: $white-c; color: $blue; @include transition-all(0.4s);}
      &:hover, &:hover i {color: $blue; @include transition-all(0.4s);}
      @include transition-all(0.4s);
    }
    input.chaptcha_code{text-align: center;}
  }

  .text-box{
    .text-editor{
      //background: $color1-c;
      background: $blue;
      padding: 22px 22px 7px 22px;
      color: $white-c;

      //p, h3, a{color: #ffffff;}

      table{
        border: 0;
        font-size: .9375rem;
        margin-top: 22px;

        tbody {
          border-bottom: 1px solid rgba(32, 32, 32, 0.30);
        }

        td {
          border: 0;
          border-top: 1px solid rgba(32, 32, 32, 0.30);
          line-height: 26px;
          padding: 40px 0 40px 45px;
          position: relative;

          &:before{
            content:"";
            font-family: 'FontAwesome';
            position: absolute;
            left: 5px;
            @include center(y);
            font-size: 1.25rem;
            width: 30px;
            display: inline-block;
            text-align: center;
            color: $white-c;
          }
        }

        tr:nth-of-type(1) td:before {content: "\f041";}
        tr:nth-of-type(2) td:before {content: "\f095";}
        tr:nth-of-type(3) td:before {content: "\f0e0";}
        tr:nth-of-type(4) td:before {content: "\f19c";}
      }
    }
  }

  #contact_form_error{color: $red-c; text-align: right; padding-right: 15px; position: absolute; bottom: 10px; right: 0px;}
  #success{color: $color1-c; font-size: 1.15rem; font-weight: bold; position: absolute; right: 0; top: -2px; padding: 0 15px; background: #FFF;}

  @include media-breakpoint-down(md) {
   .form-box{
        .col-md-12{overflow: hidden;}
      }
  }

  @include media-breakpoint-only(xs) {

    .contact-data {

      table{

        td {
          padding: 15px 0 15px 40px;

          &:before{
            left: 5px;
          }
        }
      }
    }
  }

}

//////////////////////////////////////////////////////////
//FAQ PAGE
//////////////////////////////////////////////////////////
.faq-page {

  .faq-accordion {
    clear: both;

    .ui-accordion-header-icon{
      display: none;
    }
  }

}

.faq-accordion{

  .accordion-block {

    > h3 {
      margin: 0;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 26px;
      padding: 12px 45px 12px 0;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid $border-c;

      > span {
        position: absolute;
        right: 0; top: 10px;
        height: 30px; width: 30px;
        line-height: 30px;
        display: inline-block;
        text-align: center;
        color: $text-c;
        font-size: 1.5rem;
        @include transition-all(0.4s);
      }
    }

    &.active > h3 {
      border-bottom: none;
      color: $color1-c;

      > span {
        color: $text-c;
        @include transform(rotate(180deg));
      }
    }

    > div {
      padding: 15px 0;
    }
  }
}

/* SEARCH PAGE */
.search-page{

  .serach-form{
    position: absolute;
    right: 20px;
    top: 0px;
    display: flex;
    width: 300px;

    input[type="text"]{
      width: 100%;
      padding-right: 38px;
      font-style: italic;
      color: #999999;

      &:focus {
        font-style: normal;
        color: $color1-c;
      }
    }

    input[type="submit"]{
      width: 38px;
      height: 38px;
      background-color: transparent;
      border: none;
      text-indent: -9999px;
      padding: 0;
      display: inline-block;
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;
      z-index: 1;
    }

    &:after {
      font-family: FontAwesome;
      content: '\f002';
      position: absolute;
      right: 0;
      bottom: 0;
      color: #FFF;
      font-size: 22px;
      background: $color1-c;
      padding: 4px 9px 5px;
    }
  }
}
.error-page {
  padding: 70px 0 70px;
  .text-editor {
    text-align: center;
    h1{
      font-size: 2rem;
    }
    h4{
      line-height: 1.6;
    }
  }
  a{
    color: $color1-c;
    &.btn-tr{
      margin-top: 20px;
      &:hover {
        padding-right: 18px;
      }
    }
  }
}