.c-slider {
  position: relative;
  .slick-slide {
    margin: 0 12px;
  }
  .slick-list {
    margin: 0 -12px;
  }
}

.c-slider-arr {
  position: absolute;
  display: inline-block;
  text-align: center;
  top: 50%;
  cursor: pointer;
  z-index: 1;
  @include translate(0, -50%);
  &.prev-slide{
    left: 0;
  }
  &.next-slide{
    right: 0;
  }
}

//////////////////////////////
//HOMEPAGE SLIDER
//////////////////////////////
.homepage-slider {
  .c-slider-arr {
    color: white-c !important;
    border-color: $white-c !important;
    width: 50px;
    span{
      color: $white-c;
    }
    &.prev-slide {
      left: 40px;
    }
    &.next-slide {
      right: 40px;
    }
    &:hover, span:hover{
      color: $color2-c!important;
      border-color: $color2-c!important;
    }
    &:hover span {
      color: $color2-c !important;
      border-color: $color2-c !important;
      @include transition-all(.4s);
    }
  }

  .mobile-video-loop{
    position: relative;
    // top: 0;
    // left: 0;
    //height: 725px;
    // top: -60px;
  }
  .slick-dots{
    position: absolute;
    bottom: 15px;
    text-align: center;
    width: 100%;

    li{
      display: inline-block;
      margin: 0 4px;

      button{
        border: 1px solid $color2-c;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        padding: 0;
        margin: 0;
        text-indent: -999999px;
        background: none transparent;

        &:hover{
          background-color:  $color2-c;
        }
      }

      &.slick-active{
        button{background-color:  $color2-c;}
      }
    }

  }

  .slick-slide {
    position: relative;
    overflow: hidden;
  }

  .text-block-holder {
    position: absolute;
    height: 100%; width: 100%;
    left: 0; top: 0;

    > .container {
      position: relative;
      height: 100%;
    }
  }

  .text-block {
    text-align: left;
    padding: 0;
    display: inline-block;
    position: absolute;
    @include center(y);
    top:50%;
    left: 15px;
    opacity: 0;
    width: 44%;
    @include transition(opacity 2s 0.5s);
    z-index: 2;
  }

  .text1 {
    font-size: 3.75rem;
    font-weight: 700;
    color: $white-c;
    line-height: 1.2;
    margin-bottom: 40px;
    text-transform: uppercase;
    @include transition(opacity 2s 1s, left 2s);
  }

  .text2 {
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 1.2;
    color: $white-c;
    padding-bottom: 20px;
    @include transition(opacity 2s 1s, left 2s);
  }

  .text3 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    color: $white-c;
    padding-bottom: 28px;
    @include transition(opacity 2s 1s, left 2s);
  }

  .slick-active {

    .text-block {
      opacity: 1;
    }

    .text1, .text2, .text3 {
      opacity: 1;
      left: 0;
      z-index: 1;
    }

    .link-block a {
      opacity: 1;
      top: 0;
      z-index: 1;
    }
  }
}

// .homepage-slider {
//   height: 725px;

//   .slick-slide {
//     height: 725px;
//   }
// }
.services-slider{
  .c-slider-arr{
    .slick-slide {
      margin: 0 25px;
    }
    .slick-list {
      margin: 0 -25px;
    }
    &.prev-slide {
      left: -85px;
    }
    &.next-slide {
      right: -85px;
    }
  }
  @include media-breakpoint-down(md){
    .c-slider-arr{
      &.prev-slide {
        left: -55px;
      }
      &.next-slide {
        right: -55px;
      }
    }
  }
  @include media-breakpoint-down(xs){
    .c-slider-arr{
      &.prev-slide {
        left: -10px;
      }
      &.next-slide {
        right: -10px;
      }
    }
  }
}
.latest-news-slider{
  .c-slider-arr{
    &.prev-slide {
      left: -85px;
    }
    &.next-slide {
      right: -85px;
    }
  }
  @include media-breakpoint-down(md){
    .c-slider-arr{
      &.prev-slide {
        left: -55px;
      }
      &.next-slide {
        right: -55px;
      }
    }
  }
  @include media-breakpoint-down(xs){
    .c-slider-arr{
      &.prev-slide {
        left: -10px;
        svg{
          path{
            fill: $white-c;
          }
        }
      }
      &.next-slide {
        right: -10px;
        svg{
          path{
            fill: $white-c;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .homepage-slider {
    .text1{
      margin-bottom: 5px;
    }
    .c-slider-arr.prev-slide { left: 5px; }
    .c-slider-arr.next-slide { right: 5px; }
  }
}

@include media-breakpoint-between(md, lg) {
  .homepage-slider {

    .text-block {
      padding: 20px 10px;
    }

    .text1 {
    }

    .text2 {
      font-size: 36px;
    }
  }

  // .homepage-slider {
  //   height: 400px;

  //   .slick-slide {
  //     height: 400px;
  //   }
  // }
}

@include media-breakpoint-only(sm) {
  .homepage-slider {

    .text-block {
      padding: 15px 10px;
    }

    .text1 {
      font-size: 2rem;
    }

    .text2 {
      font-size: 24px;
    }
  }

  // .homepage-slider {
  //   height: 300px;

  //   .slick-slide {
  //     height: 300px;
  //   }
  // }
}

@include media-breakpoint-only(xs) {
  .homepage-slider {

    .c-slider-arr {
      display: none!important;
    }

    .text-block {
      //padding: 15px 10px;
      width: 50%;
      top: 45%;
      .btn-tr{
        padding: 10px;
        :hover {
          padding-right: 10px;
        }
        .fa{
          display: none;
        }
      }
    }

    .text1 {
      font-size: 1.5rem;
    }

    .text2 {
      font-size: 18px;
    }

    .link-block a {
      line-height: 15px;
      padding: 3px 10px;
      font-size: 10px;
    }
  }

  // .homepage-slider {
  //   height: 250px;

  //   .slick-slide {
  //     height: 250px;
  //   }
  // }
}

//////////////////////////////
// LATEST NEWS HOME SLIDER
//////////////////////////////
