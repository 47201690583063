/* Text Below Slider */

.text-below-slider{
  background: $color2-c;
  padding: 20px 0;
  color: $white-c;
  font-size: 1rem; font-weight: bold;
  line-height: 1.2;
  margin-bottom: 40px;
}

@include media-breakpoint-down(sm) {
  .btn-tr{margin-top: 15px;}
}