/* Services Baner - Home & Page Services List */

.services {
  padding: 60px 0;

  .service {
    border: 1px solid #DDDDDD;
    background: #F5F5F5;

    .img-s {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 256px;

    }

    .text-block {
      background-color: $blue;
      padding: 18px 27px;
      min-height: 89px;

      .title-se {
        color: $white-c;
        text-align: center;
        a {
          color: $white-c;
        }
      }
    }

  }

  .service:hover h3 {
    color: $color2-c;
    @include transition-all(.4s);
  }
  @include media-breakpoint-down(md){
    padding: 20px 0;
    .service{
      .text-block{
        padding: 18px 5px;
      }
    }

  }
}

.services-page {
  .service{
    margin-bottom: 30px;
  }
  .services {
    margin: 0;
    padding: 0;
    @extend .clearfix;
  }

  .text-editor {
    margin-bottom: 30px;
    padding: 0 30px;
    text-align: center;
  }
  @include media-breakpoint-down(md){
    .service{
      margin-bottom: 20px;
    }
  }
}