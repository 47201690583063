.text-editor {
  line-height: 1.4;

  h1 {
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 1rem 0;
    margin-bottom: 1rem;
    border-bottom: 1px solid $border-c;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  h3 {
    color: $color1-c;
    font-size: 1.125rem;
    font-weight: bold;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 600;
  }

  h5 {
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
  }

  a{
    color: $color2-c;
    &:hover{
      text-decoration: underline;
    }
  }

  ul{
    padding-left: 15px; margin-left: 7px;

    li{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        top: 9px; left: -15px;
        width: 3px; height: 3px;
        background-color: $black-c;
        border-radius: 50%;
      }
    }
  }

  ol{
    padding-left: 22px;

    li{
      position: relative;
    }
  }

  blockquote {
    padding: 1rem;
    border-left: 5px solid $color1-c;
    background-color: $gray-c;
    margin-bottom: 0;
    font-weight: 600;
  }
  
  @include media-breakpoint-down(md) {
    td {
      width: 100% !important;
      display: inline-block;
      padding-bottom: 10px;
    }
  }
}
