.abaut-3{
  padding: 60px 0;
  .about-box{
    border: 1px solid #D9D9D9; 
    .img-block{
      padding: 60px 25px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 176px;
    }
    .text-block{
      text-align: center;
      padding: 25px 30px;
      .title-a{
        font-size: 1.75rem;
        font-weight: 700;
        padding-bottom: 25px;
      }
      .description{
        min-height: 140px;
      }
    }
  }
}
.abaut-2{
  position: relative;
  &::before,
  &::after{
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
  }
  &::before{
    left: 0;
    background-color: $blue;
  }
  &::after{
    background-color: $gray-c;
    right: 0;
  }
  .b-diff{
    position: relative;
    z-index: 1;
    &:first-child{
      padding-right: 0;
    }
    &:last-child{
      padding-left: 0; 
      .title-s{
        color: $black-c;
      }
      .about{
        background-color: #EFEFEF;
        color: $black-c;
      }
    }
  }
  .about{
    background-color: $blue;
    padding: 60px 0;
    color: $white-c;
    .title-s{
      color: $white-c;
    }
    table{
      tr{
        td{
          padding: 25px;
          font-size: 1.125rem;
          font-weight: 700;
        }
      }
    }
  }
}
.b-right-block{
  .b-right{
    position: relative;
    .img-s{
      display: block;
      width: 100%;
      img{
        width: 100%;
      }
    }
    .text-block{
      position: absolute;
      bottom: 0;
      padding: 40px 20px;
      width: 100%;
      .title-se{
        color: $white-c;
        font-size: 3.75rem;
        a{
          color: $white-c;
        }
        &:hover{
          a{
            color: $white-c;
          }
        }
      }
    }
  }
}
